<template>
  <div class="noNavTabCard" v-if="sectionData" style="width: 100%">
    <div class="cardHead">
      {{ sectionData.sectionTitle }}
      <span class="featuredFont"
        >({{ $g("section.total") }} {{ sectionData.totalQuestions }}
        {{ $g("section.questions") }},{{ $g("section.each") }}
        {{ sectionData.pointsEach }} {{ $g("section.points") }})
      </span>
    </div>
    <div class="cardBody">
      <div
        class="section-questions mb-4"
        v-for="(question, index) in sectionData.questionList"
        :key="question.quizQuestionId"
      >
        <div class="pop-flex" style="align-items: start">
          <div class="index mr-4">{{ index + 1 }}.</div>
          <div style="flex: 1">
            <div v-html="question.questionTitle"></div>
            <k-form-group
              :noLocale="true"
              :id="question.quizQuestionId"
              class="wordWrap"
            >
              <k-form-radio-group
                v-if="question.questionType == 1"
                :options="question.optionList"
                @change="selectQuestion"
                v-model="questionContent[question.quizQuestionId]"
                :name="question.quizQuestionId"
                stacked
              ></k-form-radio-group>
              <k-form-checkbox-group
                v-else
                :options="question.optionList"
                v-model="questionContent[question.quizQuestionId]"
                @change="selectQuestion"
                :name="question.quizQuestionId"
                stacked
              ></k-form-checkbox-group>
            </k-form-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    section: {
      type: Object,
      default() {
        return;
      },
    },
  },
  data() {
    return {
      sectionData: null,
      optionsMap: new Map(),
      questionContent: {},
    };
  },
  mounted() {
    this.handleSection();
  },
  methods: {
    handleSection() {
      this.sectionData = JSON.parse(JSON.stringify(this.section));
      this.sectionData.questionList.forEach((ele) => {
        this.$set(
          this.questionContent,
          ele.quizQuestionId,
          ele.questionType == 1 ? "" : []
        );
        ele.optionList = ele.optionList.map((item) => {
          this.optionsMap.set(item.quizQuestionOptionId, item);
          return {
            value: item.quizQuestionOptionId,
            html: item.optionTitle,
            quizQuestionId: item.quizQuestionId,
          };
        });
      });
    },
    handleOptions(result, key) {
      let option = this.optionsMap.get(key);
      if (option) {
        let {
          quizSectionId,
          quizQuestionId,
          quizQuestionOptionId: quizQuestionOptionIdList,
        } = option;
        result.push({
          quizSectionId,
          quizQuestionId,
          quizQuestionOptionIdList,
        });
      }
    },
    selectQuestion(val) {
      let result = [];
      for (const [, value] of Object.entries(this.questionContent)) {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            this.handleOptions(result, item);
          });
        } else if (value) {
          this.handleOptions(result, value);
        }
      }
      this.$emit("sectionResult", result);
    },
  },
};
</script>

<style lang="scss" scoped>
.wordWrap {
  word-wrap: break-word;
}
</style>

<template>
  <div class="layoutContent">
    <k-alert-msg ref="lblMsg" />
    <home-title
      :description="SettingMsg"
      :showpic="
        getConfigVal(curPageConfigAndDefaultPageConfig, 'showPic', 'vIf')
      "
    />
    <div v-if="previewData" class="contentParty">
      <div class="preview-title">
        {{ previewData.title }}
      </div>
      <preview-section
        :section="section"
        v-for="(section, index) in previewData.sectionList"
        @sectionResult="(value) => sectionResult(value, index)"
        :key="section.quizSectionId"
      />
      <div class="btn-box" style="text-align: center">
        <k-button @click="submit" class="w-150"> submit </k-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getTakeQuizInfo, saveQuiz } from "../../api/training";
import { getPortalPageSetting } from "@/api/home";
import PreviewSection from "./components/preview-section.vue";
import {
  repResult,
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
} from "fe-infrastractures";
import HomeTitle from "@/views/components/homeTitle";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
export default {
  components: { PreviewSection, HomeTitle },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      curPageConfigAndDefaultPageConfig,
      getConfigVal,
      SettingMsg: "",
      previewData: {},
      allResultMap: new Map(),
    };
  },
  methods: {
    saveAction() {
      let result = [];
      for (const [, value] of this.allResultMap) {
        result.push(...value);
      }
      saveQuiz({
        eleClassQuizId: this.$route.query.id,
        quizQuestionOptionList: result,
      }).then((res) => {
        if (res.data.status === repResult.success) {
          this.$router.push({
            name: "quiz-successful",
            query: {
              id: this.$route.query.id,
              courseInfoId: this.$route.query.courseInfoId,
              data: JSON.stringify(res.data.info),
            },
          });
        } else {
          this.$refs.lblMsg.message(res.data.status, res.message);
        }
      });
    },
    submit() {
      let pmOpt = this.$confirm({
        title: this.$g("const.confirm.confirm"),
        content: this.$g("const.confirm.save"),
        cancelType: "secondary",
        okText: this.$g("const.confirm.saveYes"),
        cancelText: this.$g("const.confirm.no"),
      });
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        this.saveAction();
      });
    },
    sectionResult(res, index) {
      this.allResultMap.set(index, res);

      console.log("this.allResultMap", this.allResultMap);
    },
  },
  mounted() {
    getPortalPageSetting(pageNameEnum.TrainingClassTakeQuiz).then((res) => {
      this.SettingMsg = res.data?.description;
    });
    if (this.$route.query.id) {
      getTakeQuizInfo(this.$route.query.id).then((data) => {
        this.previewData = data.data;
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.preview-title {
  text-align: center;
  line-height: 60px;
  font-weight: bold;
  font-size: 20px;
}
</style>
